import React, {useEffect, useState} from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";

import * as classNames from "@css/component/BreadCrumbs.module.scss";
import clsx from "clsx";

/**
 * @type BreadcrumbsProps
 */
export type BreadcrumbsProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    breadCrumbs: {
        text: string;
        url: string;
    }[];
    variant?: string;
    parents?: string | [];
};

/**
 * @const BreadCrumbs
 */
const BreadCrumbs= (props: BreadcrumbsProps) => {
    const {
        breadCrumbs,
        variant,
        parents,
        ...restProps
    } = props;

    const [viewBreadCrumbs, setViewBreadCrumbs] = useState([]);

    const listBreadCrumbCategories = (category) => {
        return category.split("/").map((path, index, arrayLength) => {
            const lastItem = arrayLength.length - 1;

            return {
                text: path.replace("-", " "),
                url: index === lastItem ? category : path
            }
      });
    };

    useEffect(() => {
        setViewBreadCrumbs(breadCrumbs.slice(1));

        if(parents) {
            const listCategories = listBreadCrumbCategories(parents);
            setViewBreadCrumbs([ { category: listCategories }, ...breadCrumbs ] );
        }
    }, [parents]);

    return (
        <nav { ...restProps } aria-label="BreadCrumb"
             className={ clsx(classNames.breadCrumbs, {
                 [classNames.advisors]: variant == "advisors",
                 [classNames.labelNewsSingle]: variant == "labelNewsSingle"
             }) }>
            <ul>
                <li>
                    <a
                        className={ classNames.path }
                        href="/"
                        title="Woninglabel.nl"
                    >
                        <span>Home</span>
                    </a>
                </li>

                { viewBreadCrumbs && viewBreadCrumbs.map((crumbsPath, index) => (
                    <li key={ index }>
                        { crumbsPath.category &&
                        crumbsPath.category.length > 0 &&
                        crumbsPath.category.map((category, index) => (
                            <a
                                key={ index }
                                className={ classNames.pathCategory }
                                href={ `/${ category.url }` }
                                title={ category.text }
                            >
                                <span dangerouslySetInnerHTML={{ __html: category.text }} />
                            </a>
                        )) }

                        { !crumbsPath.category && <a
                            className={ classNames.path }
                            href={ crumbsPath.url }
                            title={ crumbsPath.text }
                        >
                            <span dangerouslySetInnerHTML={{ __html: crumbsPath.text }} />
                        </a> }
                    </li>
                )) }
            </ul>
        </nav>
    );
};

export default BreadCrumbs;
